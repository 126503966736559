<template>
    <div>
        <Menu as="div" class="relative inline-block text-left p-1">
            <MenuButton
                class="flex-shrink-0 rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                <Icon name="heroicons:square-2-stack" class="w-6 h-6" />
            </MenuButton>


            <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                    class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    <div class="px-4 py-3">
                        <p class="text-sm">Active Organisation</p>
                        <p class="text-sm font-medium text-gray-900 truncate">{{ useActiveTenantId() }}</p>
                    </div>
                    <div class="py-1">
                        <MenuItem v-for="tenantId in auth.userTenantAdminTenants " :key="tenantId" v-slot="{ active }">
                        <a @click="useSwitchTenant(tenantId)"
                            class="cursor-pointer flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">

                            <div class="ml-2 text-sm">
                                <div class="font-medium text-gray-900 dark:text-gray-300">
                                    <div>{{ tenantId }}</div>
                                    <p id="helper-radio-text-4"
                                        class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                        Switch to {{ tenantId }}</p>
                                </div>
                            </div>
                        </a>
                        </MenuItem>
                    </div>

                </MenuItems>
            </transition>
        </Menu>
    </div>
</template>

<script setup lang="ts">
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from "@headlessui/vue";
import { useAuthState } from "~/stores/auth";


// Stores
const auth = useAuthState()


</script>

